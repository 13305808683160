




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import dashboardHeader from '@/components/dashboard/header.vue'
import dashboardAside from '@/components/dashboard/aside.vue'
import { Getter, Action } from 'vuex-class'
@Component({
  components: {
    dashboardHeader,
    dashboardAside
  }
})
export default class OcrText extends Vue {
  @Action('caseUpdate') caseUpdate: Function
  @Action('createCase') createCase: Function
  @Getter('newCaseId') newCaseId: number
  @Prop()
  detectData: any[]
  @Prop()
  type: string
  @Prop()
  caseId: number
  @Prop()
  dataGet: boolean

  detectText: string[] = []
  ruleForm: any = {}
  reviewable: boolean = false
  submitLoading: boolean = false
  project = JSON.parse(localStorage.getItem("projects"))

  async onSubmit() {
    this.submitLoading = true
    await this.createCase({ params: { projectId: this.project.id } })
    let computedFormData = Object.assign({}, this.ruleForm)
      // 将数据组合成后台需要的结构
      let objForm = {}
      for(let item in computedFormData){
        let obj = {
          id: Number(item),
          value: computedFormData[item]
        }
        objForm[item] = obj
      }
        // 删除出现的observe
      let delObj = Object.values(objForm)[0]
      if (Number(Object.values(delObj)[0]) === 0) {
        delete objForm['0']
      }
      let params = {
        caseId: this.newCaseId,
        projectId: this.project.id,
        query: {
          groups: [{
            id: this.detectData[0].id,
            forms: [{
              id: this.detectData[0].forms[0].id,
              fields: Object.values(objForm)
            }]
          }],
          request_review: this.reviewable
        }
    }
    this.caseUpdate({ params }).then(() => {
      this.submitLoading = false
      this.$router.push('/case/mine?status=draft')
    })
  }
  // 后台来的图片的转化,只取id
  getImagesIDs (data) {
    for(let item in data) {
      if (Array.isArray(data[item])) {
        let newItem = []
        data[item].map((v: {id: number}) => {
          newItem.push(v.id)
        })
        data[item] = newItem
      }
    }
    return data
  }
   @Watch('detectData', { immediate: true, deep: true })
   onDetectDataChange(val: any, old: any) {
     if (this.type === 'image') {
       if (val.length !== 0) {
          val.map((item, index) => {
            this.detectText.push(item.join(' '))
          })
       }
      } else {
        if (typeof(val) !== undefined) {
          if (val.length !== 0) {
            this.ruleForm = []
            
            val[0].forms[0].fields.map((v, index) => {
              let obj = {}
              obj[v.id] = v.value
              this.ruleForm = Object.assign({}, this.ruleForm, obj)
            })
            this.ruleForm = this.getImagesIDs(this.ruleForm)
          }
        }
      }
   }
}
