




























































import Vue from 'vue'
import Component from 'vue-class-component'
import fileUpload from '@/components/common/fileUpload.vue'
import detect from '@/components/ocr/detect.vue'
import { Getter, Action } from 'vuex-class'

@Component({
  components: {
    fileUpload,
    detect
  }
})
export default class OcrIndex extends Vue {
  labelPosition: string = 'right'
  type: string = 'image'
  imgUrl: string = ''
  dialogTableVisible: boolean = false
  isDetect: boolean = false
  imgLoading: boolean = false
  imgFlag: boolean = false
  dataGet: boolean = false
  importForm: object = {
    type: 'image',
    format: '',
    file: ''
  }
  formatOptions: any[] = [{
    value: 'PNG',
    label: 'PNG'
  }, {
    value: 'JPG',
    label: 'JPG'
  }]
  detectTitle: string = '文字预览'
  detectData: any[] = []
  imgDisplay (url, data) {
    this.imgFlag = true
    this.imgUrl = url
    this.detectData = data
    this.isDetect = true
    this.dataGet = true
  }
  typeChange (value) {
    (this.importForm as any).type = value
    this.isDetect = false
    this.imgFlag = false
    if (value === 'image') {
      this.detectTitle = '文字预览'
    } else {
      this.detectTitle = '病历库字段预览'
    }
    (this.$refs.fileUpload as any).clearFiles()
  }
  importData () {
    this.dialogTableVisible = true
  }
  imgDelete () {
    this.isDetect = false
    this.imgFlag = false
  }
}
